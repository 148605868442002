'use client';

// Error boundaries must be Client Components

import { useEffect } from 'react';

import sendAppError from '@/libs/logger/requests/send-app-error';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    sendAppError({
      cause: error.cause,
      digest: error.digest,
      message: error.message,
      name: error.name,
      stack: error.stack,
    });
  }, [error]);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => { reset(); }
        }
      >
        Try again
      </button>
    </div>
  );
}
